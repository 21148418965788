import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Layout from '../components/LayoutNoFooter';
import Content, { HTMLContent } from '../components/Content';
import BlogSignUp from '../components/BlogSignUp/BlogSignUp';

export const BlogPostTemplate = ({
  content,
  featuredimage,
  date,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  link,
  excerpt,
  next,
  previous,
  nextTitle,
  prevTitle
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-2">
            <p>
              {date}
            </p>
          </div>
          <div className="column is-10">
            <h2>{title}</h2>
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            {featuredimage ? (
              <div className="featured-thumbnail">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: `featured image thumbnail for post ${title}`,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="columns">
          <div className="column is-7 is-offset-3">
            <PostContent content={content} />

            <div className="small-gap" />

            {/* render read more if link is in use */}
            {link ? (
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <a href={link} target="_blank">
                      <button type="button " className=" button button-trans is-outlined is-rounded is-fullwidth OTButton-secondary OTButton">Read More ↗</button>
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

      </div>

      <div className="small-gap" />

      <div className="container">
        <div className="columns is-mobile">
            <div className='column is-6 left'>
              {/* check if next and previous are journal pages */}
              {previous && previous.slice(0,5) == '/jour' && (<p className="caseStudy--button">
                <Link className="caseStudy--button" to={previous}>
                  ← 
                  <span className="caseStudy--button">Previous: {prevTitle}</span>
                </Link>
              </p>)}
            </div>
            <div className='column is-6 right'>
              {next && next.slice(0,5) == '/jour' && (<p className="caseStudy--button">
                <Link className="caseStudy--button" to={next}>
                  <span className="caseStudy--button">Next: {nextTitle}</span>
                  →
                </Link>
              </p>)}
            </div>
        </div>
      </div>

      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-3">
            <br />
            <h6>Keep up with the latest developments in data science. 
              One email per month.</h6>
            <BlogSignUp />
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data , pageContext}) => {
  const { markdownRemark: post } = data;
  const imagePath = post.frontmatter.featuredimage && `https://ortom.ai${post.frontmatter.featuredimage.publicURL}`;
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        excerpt={post.excerpt}
        featuredimage={post.frontmatter.featuredimage}
        link={post.frontmatter.link}
        date={post.frontmatter.date}
        next={pageContext.next && pageContext.next.fields.slug}
        nextTitle={pageContext.next && pageContext.next.frontmatter.title}
        previous={pageContext.prev && pageContext.prev.fields.slug}
        prevTitle={pageContext.prev && pageContext.prev.frontmatter.title}
        helmet={(
          <Helmet titleTemplate="%s | Ortom Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.excerpt}`}
            />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.excerpt} />
            <meta property="og:image" content={imagePath} />
            <meta property="og:url" content="https://ortom.co.uk" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:site_name" content="Ortom Ltd." />
          </Helmet>
        )}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        date(formatString: "DD.MM.YY")
        title
        description
        tags
        link
        featuredimage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
