import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo-ortom.svg';
import './Footer-blog.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer footer-inverse">
        <div id="photo-container-inverse">
          <div className="photobanner-inverse">
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
          </div>
        </div>
        <div className="footerMenu container">
          <p className="footerMenu--item footerMenu-inverse--item legal dark">
            &#169;2025
          </p>
          <p className="footerMenu--item footerMenu-inverse--item legal dark">
            <a
              href="https://www.linkedin.com/in/tomliptrot1/"
            >
              LINKEDIN
            </a>
          </p>
          <p className="footerMenu--item footerMenu-inverse--item legal dark">
            <a
              href="https://clutch.co/profile/ortom"
            >
              CLUTCH.CO
            </a>
          </p>
          <p className="footerMenu--item footerMenu-inverse--item legal dark">
            <Link
              to="/privacy"
            >
              TERMS &#38; PRIVACY
            </Link>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
